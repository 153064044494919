<form [formGroup]="scenarioForm" (ngSubmit)="create($event)"
  class="flex flex-col relative z-50 w-full box-border bg-white p-6 max-w-md shadow-small overflow-y-hidden font-body rounded-md space-y-4">
    <div class="flex items-center justify-between gap-6">
      <header>
        <h2 class="font-medium text-lg">{{ (data.clone ? 'scenario.duplicate.title' : 'data.scenario.newScenario')  |  translate }}</h2>
        @if(data.clone) {
          <p class="text-sm text-gray-500 mb-3 mt-2" 
            [innerHTML]="'scenario.duplicate.description' | translate: {name: duplicateScenarioName}"></p>
        }
      </header>
      <button type="button" (click)="onNoClick()"
        class="h-8 absolute appearance-none select-none top-1 right-1 p-2 text-foreground-500 rounded-full hover:bg-default-100 active:bg-default-200 tap-highlight-transparent outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2">
        <app-svg-images svgName="x-mark"></app-svg-images>
      </button>
    </div>
    <div class="space-y-1.5">
      <label translate="data.scenario.chooseName" class="block text-sm font-medium text-gray-700"></label>
      <input cdkFocusInitial
        class="block w-full border rounded-md shadow-inner py-2.5 px-3 focus:outline-none text-sm"
        [ngClass]="(scenarioForm.invalid && scenarioForm.touched) ? 'border-red-500 ring-1 ring-red-500' : 'border-gray-300 focus:ring-digital-blue focus:border-digital-blue'"
        formControlName="name" [placeholder]="'scenario.name' | translate">
        @if ((scenarioForm.invalid && scenarioForm.touched)) {
          <div class="text-sm text-red-500">{{getErrorMessage()}}</div>
        }

        <div [class.-space-y-4]="!data.clone">
          <div class="mt-3">
            @if (!data.clone) {
              <mat-checkbox formControlName="duplicate" color="primary" class="text-sm" (change)="onDuplicateChange($event)">
                {{ "data.scenario.duplicateFromScenario" | translate }}
              </mat-checkbox>
              <mat-form-field appearance="outline" class="ml-3 scenario-list-dropdown">
                <mat-select panelWidth='null' formControlName="scenarioList">
                  @for (scenario of scenarios; track scenario.id) {
                    <mat-option [value]="scenario.id">
                      {{ scenario.name }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            }
          </div>
          @if (isInternalUser$ | async) {
            <div class="-mt-4">
              <mat-checkbox formControlName="internal" color="primary" class="text-sm">
                {{ "data.scenario.setInternal" | translate }}
              </mat-checkbox>
            </div>
          }
        </div>
    </div>

    <div class="flex items-center justify-end gap-8" [class.pt-4]="data.clone">
      <button type="button" class="font-medium hover:underline active text-sm" translate="global.cancel"
        (click)="onNoClick()">
      </button>

      <app-button variant="primary" type="submit" [disabled]="scenarioForm.invalid">
        <span>{{ (data.clone ? 'scenario.actions.duplicate': 'data.scenario.addScenario') | translate }}</span>
        @if (isSaving$ | async) {
          <app-svg-images class="h-4 animate-spin" svgName="arrow-path"></app-svg-images>
        }
      </app-button>
    </div>
</form>