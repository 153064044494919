import { Component, Input, OnInit } from '@angular/core';
import { DynamicComponent } from './dynamic.component';
import { Activity } from '../store';
import { IScenario } from '../../../views/scenario/model/scenario.model';
import { HOME_ROUTE, PROCESSED_STATES } from '../../app.constants';
import { JobStatus } from 'src/app/views/scenario/model/scenario-job-dto.model';

@Component({
  selector: 'app-scenario-job',
  templateUrl: './scenario-job.component.html',
  styleUrls: ['./scenario-job.component.scss']
})
export class ScenarioJobComponent implements OnInit, DynamicComponent {

  constructor() {}

  @Input() notification!: Activity;

  get scenario(): IScenario {
    return this.notification.data as IScenario;
  }

  get error(): string {
    return this.notification.errors?.userMessage || null;
  }

  ngOnInit(): void {
  }

  isProcessing(notification: Activity) {
    return !PROCESSED_STATES.includes(notification.message);
  }

  goToScenario(status: any): string[] {
    const baseRoute = [HOME_ROUTE, this.scenario?.product.id, 'scenarios', this.scenario?.id].map(val => val.toString());
    switch (status) {
      case JobStatus.PROCESSED:  
        return [...baseRoute, 'milestones'];
      default:
        return [...baseRoute, 'segments'];
    }
   
  }
}
