import { Component, inject, Inject, OnInit } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Store } from "@ngrx/store";
import { AppState, getAuthUser, selectProductById } from 'src/app/store';

@Component({
  selector: 'app-config-summary-dialog',
  template: `
  <app-dialog [showActions]="false" (close)="dialogRef.close()">
    <app-scenario-config-summary 
      custom-dialog="content" 
      [hideHeader]="true"
      [product]="(product$ | async)" 
      [user]="(user$ | async)">
    </app-scenario-config-summary>
  </app-dialog>
  `,
  styles: `
    :host {
      display: block;
    }
  `
})
export class ConfigSummaryDialogComponent {
  private store = inject(Store<AppState>);

  user$ = this.store.select(getAuthUser);
  product$ = this.store.select(selectProductById(this.data.productId))

  constructor(@Inject(DIALOG_DATA) public data, public dialogRef: DialogRef<any>) {
  }
}
