@if (scenario$ | async; as scenario) {
  @if ((scenario.stale && isProcessed(scenario)) || isNewOrDuplicated(scenario)) {
    <div
      @slideDownPositionAnimation
      class="notification py-1 bg-info"
      [ngClass]="{
        'opacity-[0.8] dark:bg-white/30' : scenario.locked,
        'opacity-[0.95]': !scenario.locked
      }">
      <span class="inline-block mr-2" [translate]="(isNewOrDuplicated(scenario)) ? 'data.scenario.runNow' : 'data.scenario.updatesPending'"></span>
      @switch (scenario.jobTrigger) {
        @case ('AUTO') {
          <app-svg-images
            class="inline-block h-4.5"
            [appTooltip]="'diff.scenarioUpdates.auto' | translate"
            svgName="arrow-path"></app-svg-images>
        }
        @case ('MANUAL') {
          <app-svg-images
            class="inline-block h-4.5"
            [appTooltip]="'diff.scenarioUpdates.manual' | translate"
            svgName="snowflake"></app-svg-images>
        }
        @default {
          <app-svg-images
            class="inline-block h-4.5"
            [appTooltip]="'diff.scenarioUpdates.schedule' | translate"
            svgName="clock"></app-svg-images>
        }
      }
      <mat-spinner-button class="ml-2" [options]="options" [loading]="loading" [disabled]="scenario.locked" (click)="handleAction(scenario)">
        {{ 'data.scenario.changes' | translate }}
      </mat-spinner-button>
      @if (!isNewOrDuplicated(scenario)) {
        <mat-spinner-button [options]="options" [loading]="loadingUpdates" (click)="scenarioChanges(scenario)">
          {{ 'data.scenario.updates' | translate }}
        </mat-spinner-button>
      }
    </div>
  }
  @if (isProcessing(scenario)) {
    <app-scenario-progress
      [embedded]="false"
      [isNotificationBar]="true"
      [scenario]="scenario">
    </app-scenario-progress>
  }
  <ng-template #none></ng-template>
}
