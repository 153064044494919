import { Component, Input, OnInit } from '@angular/core';
import { LookerResponse, LookerService } from '../../../../core/services/looker.service';
import { SharedModule } from "../../../../core/shared.module";
import { Observable } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { OnDestroy } from "../../../../core/shared/rxjs-helpers";
import { Dashboard } from "../../../../store/reducers/reporting.reducer";
import { Store } from "@ngrx/store";
import { selectReport } from "../../../../store/selectors/reporting.selectors";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { filter, finalize, tap } from "rxjs/operators";
import { TitleService } from "../../../../core/services/title.service";
import { setPageHeaderContent } from 'src/app/store';
import {
  ProductDashboardHeaderComponent
} from 'src/app/views/product/beta/product-dashboard-header/product-dashboard-header.component';
import { PageTrackingData } from 'src/app/core/analytics-next/analytics-next';

@Component({
  selector: 'app-custom-dashboard',
  templateUrl: './custom-dashboard.component.html',
  standalone: true,
  imports: [SharedModule, ProductDashboardHeaderComponent]
})
export class CustomDashboardComponent extends OnDestroy implements OnInit {
  url$: Observable<LookerResponse>;
  report$: Observable<Dashboard>;
  loading: boolean = false;

  @Input() dashboard: string;
  @Input() theme: string = null;

  constructor(private looker: LookerService,
              private store: Store,
              private titleService: TitleService,
              private activatedRoute: ActivatedRoute) {
    super()
  }

  ngOnInit() {
    this.activatedRoute.params.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(({ report }) => {
      if (!report) {
        report = this.dashboard;
      }
      this.loading = true;
      this.url$ = this.looker.createCustomDashboardUrl(report, this.activatedRoute.snapshot.queryParams, this.theme).pipe(
        finalize(() => this.loading = false)
      );
      this.report$ = this.store.select(selectReport(report)).pipe(
        filter((report) => !!report),
        tap((report) => this.setTitle(report)),
      )
    });
  }

  setTitle(dashboard: Dashboard) {
    const title = dashboard.title;
    this.titleService.setTitle(title, this.trackingData(title));
    this.store.dispatch(setPageHeaderContent({ pageTitle: title }));
  }

  onLoaded($event) {
    const { dashboard } = $event;
    const title = dashboard.title;
    if (title?.length) {
      this.titleService.setTitle(title, this.trackingData(title));
    }
  }

  private trackingData(dashboard: string): PageTrackingData {
    return {
      name: 'Custom Looker Dashboard',
      category: 'reports',
      properties: { dashboard, tag: 'custom-looker-dashboard' }
    };
  }

}
