@let itemClasses = ['hover:no-underline', 'text-black dark:text-white/70 dark:hover:text-white', 'hover:bg-soft-blue', 'dark:hover:bg-slate-700'];
<header class="px-8 pt-3.5 pb-5 z-50 sticky flex justify-between items-center bg-soft-blue dark:bg-slate-950">
  <div class="flex items-center gap-1 dark:text-white/70 dark:hover:text-white">
    <a class="z-20 block active" [routerLink]="['/products']">
      <img alt="Kohort logo" src="//kohort-media.s3.eu-west-1.amazonaws.com/control/logo-white.png"
        class="w-auto h-4.5 hidden dark:inline-block"/>
      <img alt="Kohort logo" src="//kohort-media.s3.eu-west-1.amazonaws.com/control/logo.png"
        class="w-auto h-4.5 dark:hidden"/>
    </a>
    <span class="pl-4 opacity-50">/</span>
    <div class="flex-1">
      <router-outlet name="breadcrumbs"></router-outlet>
    </div>
  </div>
  <div class="flex-1 flex justify-center">
    <nav class="flex items-center gap-4">
      <div class="flex gap-3">
        <a [routerLink]="['/products', product.id, 'reports']"
          routerLinkActive #dashRLA="routerLinkActive"
          [ngClass]="{
                'border dark:border-slate-700/70 active bg-white dark:bg-background shadow-sm': dashRLA.isActive,
                'hover:bg-white text-gray-800 dark:hover:text-gray-800': !dashRLA.isActive
              }"
          class="py-2 px-3 rounded-full text-[13px] font-medium w-[115px] text-center flex items-center justify-center gap-2 text-black hover:no-underline dark:text-white">
          <span>{{ 'app.navigation.dashboards' | translate }}</span>
        </a>
        <app-overlay-popup position="bottom-center" data-cy="top-nav-main-links" routerLinkActive
          #rla="routerLinkActive">
          <a [routerLink]="['/products', product.id, 'scenarios']"
            [ngClass]="{
                  'border dark:border-slate-700/70 active bg-white dark:bg-background shadow-sm': rla.isActive,
                  'hover:bg-white text-gray-800 dark:hover:text-gray-800': !rla.isActive
                }"
            class="py-2 px-3 rounded-full text-[13px] font-medium w-[115px] text-center flex items-center justify-center gap-2 text-black dark:text-white/90 hover:no-underline dark:text-white">
            <span>{{ 'app.navigation.planning.title' | translate }}</span>
            <app-svg-images svgName="chevron-down" class="h-3 inline-block"></app-svg-images>
          </a>
          <div popup-content
            class="min-w-[200px] z-10 px-2.5 py-1 w-full inline-flex flex-col items-center justify-center subpixel-antialiased outline-none box-border text-small bg-white dark:bg-background rounded-lg shadow-sm mt-2 border border-gray-300 dark:border-slate-700/70">
            <div
              class="text-sm z-10 font-body max-h-[80vh] overflow-y-auto overflow-x-hidden grid grid-cols-11 divide-x dark:divide-slate-700/70 max-w-[60vw] ">
              <!-- Scenario links -->
              <div class="col-span-8 p-2 pt-1 pl-0 group/listSection">
                <div class="flex items-center gap-3 pb-2 px-4">
                  <h3 class="text-gray-500 dark:text-white/90 text-sm">{{ 'app.navigation.planning.scenarios.title' | translate }}</h3>
                  <app-svg-images svgName="square-3-stack-3d-solid"
                    class="text-gray-300 h-3 group-hover/listSection:text-teal-green"></app-svg-images>
                </div>
                <!-- Scenarios -->
                <a [routerLink]="['/products', product.id, 'scenarios']"
                  [ngClass]="itemClasses"
                  class="group active hover:bg-soft-blue px-3 py-2 font-medium gap-16 first:rounded-t-lg last:rounded-b-lg flex items-center justify-between">
                  <span class="flex-1">{{ 'app.navigation.planning.scenarios.all' | translate }}</span>
                  <div class="flex items-center justify-end">
                    <app-svg-images svgName="arrow-small-right"
                      class="h-3 opacity-10 group-hover:opacity-100"></app-svg-images>
                  </div>
                </a>
                <!-- Favourite scenarios -->
                <div
                  class="max-h-[350px] overflow-y-auto custom-scroll overflow-x-hidden border-t dark:border-slate-700/70">
                  @if (favouriteScenarios$ | async; as favourites) {
                    <div class="space-y-0.5">
                      @for (favouriteScenario of favourites; track favouriteScenario.id) {
                        <a queryParamsHandling="preserve"
                          [routerLink]="[{outlets: {aux: ['scenarios', favouriteScenario.id, 'milestones']}}]"
                          [ngClass]="itemClasses"
                          class="relative group active  px-3 py-2 font-medium gap-16 first:rounded-t-lg last:rounded-b-lg flex items-center justify-between">
                          <div class="flex-1 flex items-center gap-3">
                            <span>{{ favouriteScenario.name }}</span>
                          </div>
                          <div class="flex items-center justify-end gap-3">
                            @if (favouriteScenario.internal) {
                              <app-svg-images
                                [appTooltip]="'global.internal' | translate"
                                svgName="scenario" class="h-3 w-3 text-kohort-tertiary dark:text-white"></app-svg-images>
                            }
                            @if (favouriteScenario.default) {
                              <app-badge variant="default">
                                {{ 'data.scenario.default' | translate }}
                              </app-badge>
                            }
                            @if (favouriteScenario.budget) {
                              <app-badge variant="budget">
                                {{ 'data.scenario.budget' | translate }}
                              </app-badge>
                            }
                            <app-svg-images svgName="heart" class="h-3 text-kohort-primary"></app-svg-images>
                            <span
                              class="text-gray-400/70 group-hover:text-gray-500 text-xs">{{ favouriteScenario.id }}</span>
                            <app-svg-images svgName="arrow-small-right"
                              class="h-3 opacity-10 group-hover:opacity-100"></app-svg-images>
                          </div>
                        </a>
                      }
                    </div>
                  }
                  @if (scenarios$ | async; as scenarios) {
                    <div class="space-y-0.5">
                      @for (scenario of scenarios; track scenario.id) {
                        <a queryParamsHandling="preserve"
                          [routerLink]="[{outlets: {aux: ['scenarios', scenario.id, 'milestones']}}]"
                          [ngClass]="itemClasses"
                          class="relative group active px-3 py-2 font-medium gap-16 first:rounded-t-lg last:rounded-b-lg flex items-center justify-between">
                          <div class="flex-1 flex items-center gap-3">
                            <span>{{ scenario.name }}</span>
                          </div>
                          <div class="flex items-center justify-end gap-3">
                            @if (scenario.internal) {
                              <app-svg-images
                                [appTooltip]="'global.internal' | translate"
                                svgName="scenario" class="h-3 w-3 text-kohort-tertiary dark:text-white"></app-svg-images>
                            }
                            @if (scenario.default) {
                              <app-badge variant="default">
                                {{ 'data.scenario.default' | translate }}
                              </app-badge>
                            }
                            @if (scenario.budget) {
                              <app-badge variant="budget">
                                {{ 'data.scenario.budget' | translate }}
                              </app-badge>
                            }
                            <span class="text-gray-400/70 group-hover:text-gray-500 text-xs">{{ scenario.id }}</span>
                            <app-svg-images svgName="arrow-small-right"
                              class="h-3 opacity-10 group-hover:opacity-100"></app-svg-images>
                          </div>
                        </a>
                      }
                    </div>
                  }
                </div>
              </div>
              <!-- Product Settings -->
              <div class="p-2 pr-0 col-span-3 group/listSection">
                <div class="flex items-center gap-3 pb-2 px-4">
                  <h3 class="text-gray-500 dark:text-white/90 text-sm">{{ 'app.navigation.planning.product.title' | translate }}</h3>
                  <app-svg-images svgName="cog-6-tooth-solid"
                    class="text-gray-300 h-3 group-hover/listSection:text-digital-blue"></app-svg-images>
                </div>
                <a [routerLink]="['/products', product.id, 'settings']"
                  [ngClass]="itemClasses"
                  class="group active hover:bg-soft-blue px-3 py-2 font-medium gap-16 first:rounded-t-lg last:rounded-b-lg flex items-center justify-between">
                  <div class="flex-1 flex items-center gap-3.5">
                    <span class="flex-1">{{ 'app.navigation.planning.product.settings' | translate }}</span>
                  </div>
                  <div class="flex items-center justify-end">
                    <app-svg-images svgName="arrow-small-right"
                      class="h-3 opacity-0 group-hover:opacity-100"></app-svg-images>
                  </div>
                </a>
                <a [routerLink]="['/products', product.id, 'files']"
                  [ngClass]="itemClasses"
                  class="group active hover:bg-soft-blue px-3 py-2 font-medium gap-16 first:rounded-t-lg last:rounded-b-lg flex items-center justify-between">
                  <div class="flex-1 flex items-center gap-3.5">
                    <span class="flex-1">{{ 'app.navigation.planning.product.files' | translate }}</span>
                  </div>
                  <div class="flex items-center justify-end">
                    <app-svg-images svgName="arrow-small-right"
                      class="h-3 opacity-0 group-hover:opacity-100"></app-svg-images>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </app-overlay-popup>
      </div>
    </nav>
  </div>
  <div class="flex justify-end flex-1">
    <div class="flex items-center justify-end gap-2 flex-1">
      <app-activity-icon [orgId]="product.orgId"/>
      <app-theme-switcher/>
      <app-user-orgs-menu/>
    </div>
  </div>
</header>
