import { AfterViewInit, Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { Subscription, filter, take, timer } from 'rxjs';

@Directive({
  selector: '[appPopper]',
  standalone: true,
})
export class PopperDirective implements AfterViewInit {
  @Input() position: 'top' | 'left' | 'bottom' | 'right' | 'topLeft' | 'rightStart' = 'top';
  @Input() closeDelay: number = 0;

  private popperContent: HTMLElement;
  private popperTrigger: HTMLElement;
  private header: HTMLElement;
  private hideSubscription: Subscription = new Subscription();


  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngAfterViewInit() {
    this.popperContent = this.el.nativeElement.querySelector('[data-app-popper="content"]');
    this.popperTrigger = this.el.nativeElement.querySelector('[data-app-popper="trigger"]');
    this.header = this.el.nativeElement.ownerDocument.querySelector('header');
  }

  @HostListener('mouseenter')
  @HostListener('focus')
  onMouseEnter(): void {
    this.hideSubscription.unsubscribe();
    if (!!this.popperContent && !!this.popperTrigger) {
      this.updatepopperPosition();
      this.renderer.removeClass(this.popperContent, 'opacity-0');
      this.renderer.removeClass(this.popperContent, 'invisible');
      this.renderer.addClass(this.popperContent, 'opacity-1');
    }
  }

  @HostListener('mouseleave')
  @HostListener('blur')
  onMouseLeave(): void {
    this.hideSubscription = timer(this.closeDelay).pipe(
    filter(() => !!this.popperContent && !!this.popperTrigger),
    take(1)
   ).subscribe(() => {
      this.renderer.removeClass(this.popperContent, 'opacity-1');
      this.renderer.addClass(this.popperContent, 'opacity-0');
      this.renderer.addClass(this.popperContent, 'invisible');
    });
  }

  private updatepopperPosition(): void {
    const triggerRect = this.popperTrigger?.getBoundingClientRect();
    const popperRect = this.popperContent?.getBoundingClientRect();

    switch (this.position) {
      case 'topLeft':
        this.showOnTopLeft(triggerRect, popperRect);
        break;
      case 'top':
        if (triggerRect.top < popperRect.height) {
          this.showAtBottom(triggerRect, popperRect);
        } else {
          this.showOnTop(triggerRect, popperRect);
        }
        break;
      case 'bottom':
        if (window.innerHeight < (triggerRect.bottom + popperRect.height)) {
          this.showOnTop(triggerRect, popperRect);
        } else {
          this.showAtBottom(triggerRect, popperRect);
        }
        break;
      case 'left':
        if (triggerRect.left < popperRect.width) {
          this.showOnRight(triggerRect, popperRect);
        } else {
          this.showOnLeft(triggerRect, popperRect);
        }
        break;
      case 'right':
        if (window.innerWidth < (triggerRect.right + popperRect.width)) {
          this.showOnLeft(triggerRect, popperRect);
        } else {
          this.showOnRight(triggerRect, popperRect);
        }
        break;
      case 'rightStart':
        this.showOnRightStart(triggerRect, popperRect);
        break;
    }
  }

  private getVerticalCenter(triggerRect: DOMRect, popperRect: DOMRect): number {
    return triggerRect.top + ((triggerRect.height - popperRect.height) / 2);
  }

  private getVerticalEnd(triggerRect: DOMRect, popperRect: DOMRect): number {
    return triggerRect.bottom - popperRect.height;
  }

  private getVerticalStart(triggerRect: DOMRect, popperRect: DOMRect): number {
    // if menu fits in the viewport, align to start
    if (window.innerHeight >= (triggerRect.top + popperRect.height)) return triggerRect.top;
    // if half the menu fits in the viewport, center align
    if (window.innerHeight >= (triggerRect.bottom + popperRect.height/2)) return this.getVerticalCenter(triggerRect, popperRect);

    return this.getVerticalEnd(triggerRect, popperRect);
  }



  private showOnTop(triggerRect: DOMRect, popperRect: DOMRect): void {
    const left = (window.innerWidth < (triggerRect.right + popperRect.width)) ? (triggerRect.right - popperRect.width) : triggerRect.left + ((triggerRect.width - popperRect.width) / 2);
    this.renderer.setStyle(this.popperContent, 'top', `${triggerRect.top - popperRect.height}px`);
    this.renderer.setStyle(this.popperContent, 'left', `${left}px`);
  }

  private showOnTopLeft(triggerRect: DOMRect, popperRect: DOMRect): void {
    const top = (window.innerHeight < (triggerRect.bottom + popperRect.height)) ? triggerRect.bottom - popperRect.height : triggerRect.top;
    this.renderer.setStyle(this.popperContent, 'top', `${top}px`);
    this.renderer.setStyle(this.popperContent, 'left', `${triggerRect.left - popperRect.width}px`);
  }

  private showAtBottom(triggerRect: DOMRect, popperRect: DOMRect): void {
    const left = (window.innerWidth < (triggerRect.right + popperRect.width)) ? (triggerRect.right - popperRect.width) : triggerRect.left + ((triggerRect.width - popperRect.width) / 2);
    this.renderer.setStyle(this.popperContent, 'top', `${triggerRect.bottom}px`);
    this.renderer.setStyle(this.popperContent, 'left', `${left}px`);
  }

  private showOnLeft(triggerRect: DOMRect, popperRect: DOMRect): void {
    const top = triggerRect.top < popperRect.height ? (triggerRect.height / 2) : this.getVerticalCenter(triggerRect, popperRect);
    this.renderer.setStyle(this.popperContent, 'top', `${top}px`);
    this.renderer.setStyle(this.popperContent, 'left', `${triggerRect.left - popperRect.width}px`);
  }

  private showOnRight(triggerRect: DOMRect, popperRect: DOMRect): void {
    const top = triggerRect.top < popperRect.height ? (triggerRect.height / 2) :this.getVerticalCenter(triggerRect, popperRect);
    this.renderer.setStyle(this.popperContent, 'top', `${top}px`);
    this.renderer.setStyle(this.popperContent, 'left', `${triggerRect.right}px`);
  }

  private showOnRightStart(triggerRect: DOMRect, popperRect: DOMRect): void {
    const header = this.header?.getBoundingClientRect();
    const top = this.getVerticalStart(triggerRect, popperRect);
    // if the top is under the header lets push it down so it's not hidden
    if (top < header?.height) {
     this.renderer.setStyle(this.popperContent, 'top', `${header.height}px`);
    } else {
     this.renderer.setStyle(this.popperContent, 'top', `${top}px`);
    }
    this.renderer.setStyle(this.popperContent, 'left', `${triggerRect.right}px`);
  }
}
